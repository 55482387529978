<template>
  <div class="top-banner" v-if="data">
    <div class="top-banner__content" :style="{ background: bg }">
      <div class="d-flex align-center py-4 px-2">
        <img
          width="30px"
          :src="require('@/assets/images/icons/kampanya.svg')"
          alt=""
        />
        <p class="font-14 mx-5" :style="{color: color}">{{ data }}</p>
        <img
          v-if="!isFixed"
          width="15px"
          class="cursor-pointer "
          :src="require('@/assets/images/icons/close.svg')"
          @click="clickHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAnnouncement } from "@/services/Banner";

export default {
  data: () => ({
    bannerTitle: "",
    data: "",
    bg: "",
    color: "",
    isFixed: false,
  }),
  computed: {
    locationID() {
      return (
        localStorage.getItem("locationID") || this.$store.getters["locationID"]
      );
    },
  },
  methods: {
    clickHandler() {
      localStorage.setItem("banner", "accepted");
      this.$emit("close");
    },
  },
  async created() {
    let res = await fetchAnnouncement(this.locationID);
    this.data = res.announcement;
    this.bg = res.background;
    this.color = res.color;
    this.isFixed = res.is_fixed;
  },
};
</script>

<style lang="scss">
.top-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &__content {
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 960px) {
  }
}
</style>
