import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import "./assets/style/_variables.scss";
import "@mdi/font/css/materialdesignicons.css";
// helper
import helper from "./utils/helper";

// slick carousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import "./assets/style/rtl.scss";

import BaseButton from "./components/base/BaseButton";
import BaseCounter from "./components/base/Counter";
import BaseInput from "./components/base/Input";
import BaseDate from "./components/base/Date";
import BaseSearch from "./components/base/Search";
import BaseSelect from "./components/base/Select";
import BaseTextarea from "./components/base/Textarea";
import LoadingSpinner from "@/components/global/Spinner";

// i18n
import i18n from "./i18n";
import axios from "./plugins/axios";

// meta
import VueMeta from "vue-meta";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

// directives
import LazyLoadDirective from "./directives/LazyLoad";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.prototype.$helper = helper;
Vue.config.productionTip = false

Vue.component("base-counter", BaseCounter);
Vue.component("base-button", BaseButton);
Vue.component("base-input", BaseInput);
Vue.component("base-date", BaseDate);
Vue.component("base-search", BaseSearch);
Vue.component("base-select", BaseSelect);
Vue.component("base-textarea", BaseTextarea);
Vue.component("slick-carousel", VueSlickCarousel);
Vue.component("loading-spinner", LoadingSpinner);

Vue.directive("lazyload", LazyLoadDirective);

Vue.use(axios);
Vue.use(VueTelInput);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDo7yZx_W8BVxz9Sm9r9zffu9Tbq_ZMtm0',
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    isSm() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    isLg() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg
      );
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
