export default {
  global: {
    english: "English",
    arabic: "العربية",
    turkish: "Türkçe",
    noItems: "لم يتم العثور على منتجات",
    changeLocation: "تغيير الفرع",
    noCategory: "هذا القسم غير متوفر حاليا",
    noProduct: "هذا المنتج غير متوفر حاليا",
    searchProduct: "البحث",
    cartEmpty: "لا يوجد منتجات في السلة",
    cart: "السلة",
    total: "المحموع",
    open: "فتح",
    request: "طلب",
    completeRequest: "إتمام عملية الشراء",
    widthCoupon: "استخدام كوبون",
    addToCart: "اضافة للسلة",
    sendOrder: "اطلب الان",
    favoriteEmpty: "لا يوجد منتجات مفضلة",
    seeProducts: "مشاهدة المنتجات",
    add: "اضافة",
    backToProducts: "العودة للمنتجات",
    yourFavoriteList: "المفضلة",
    writeYourNotes: "ملاحظاتكم",
    price: "السعر",
    requestDetails: "طلب التفاصيل",
    name: "الاسم",
    delivery: "توصيل",
    note: "تفاصيل الطلب",
    sendByWhatsapp: "الطلب عن طريق الواتساب",
    yourAddress: "عنوانك أو قم بنسخ ولصق رابط العنوان الخاص بك",
    pickUp: "الإستلام من المطعم",
    none: "لايوجد",
    use: "استخدام",
    useCoupon: "استخدام الكوبون",
    enterPhone: "ادخل رقم هاتفك",
    totalPrice: "المجموع",
    couponAmount: "مبلغ الكوبون",
    generalTotal: "المجموع العام",
    productsAmount: "مجموع المنتجات",
    orderNotes: "ملاحظات الطلب",
    username: "اسم الزبون",
    userPhone: "رقم الزبون",
    toAddress: "توصيل",
    userAddress: "عنوان الزبون",
    stillNeed : 'لازلت تحتاج الى ',
    toUseCoupon: 'لاستخدام هذا الكوبون',
    yes: 'اجل',
    no: 'لايوجد',


    mustNotZero: 'يجب طلب عنصر واحد على الاقل',
    itemAddedToCart: 'تم اضافة عنصر جديد الى الكرت',
    selectLocation: 'اختر المطعم الذي تريد',
    phoneNumber: 'رقم الهاتف',
    nameRequire: 'حقل الاسم مطلوب',
    googleForm: 'للإنضمام إلى كادرنا:',
    orderLimit: 'الحد الأدنى للطلب هو  ',
    siteIsOff: 'الموقع تحت الصيانة'


  },
};
