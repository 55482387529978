import axios from "axios";

export const fetchAnnouncement = async (locationID) => {
  let { data } = await axios.get(
    `frontend/announcements/${locationID}`
  );
  if (data) {
    return data;
  }
};
