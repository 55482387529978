import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";

Vue.use(Vuetify);
const opts = {
  breakpoint: {
    scrollBarWidth: 24,
  },
  rtl: localStorage.getItem("language") == "ar" ? true : false,
  theme: {
    themes: {
      light: {
        primary: "#FC5B35",
        secondary: "#FD7A5C",
        bluebg: "#BAEBE0",
        "blue-text": "#186B6D",
        "light-primary": "#FFE9E3",
        "light-bluebg": "#E3EFFF",
        black: "#333333",
        greybg: "#F5F5F5",
        lightgreybg: "#ECECEC",
        "light-primary2": "#FFFAEB",
        greyText: "#464646",
        lightgreyText: "#909090",
        darkblue: "#344767",
        normaldarkblue: "#575F87",
        lightdarkblue: "#7B809A",
        yellow: "#FFC107",
        lightText: "#C1C1C1",
        green: "#47B649",
        lightwhite: "#FFFEFC",
        $darkblue2: "#4D5562",
        darkgreen: "#205F24",
        lightgrey: "#7A8089",
        greenText: "#3E7B3D",
      },
    },
  },
};

export default new Vuetify(opts);
