<template>
  <div class="foot z-9">
    <div class="footer-app">
      <div class="max-width">
        <v-row class="mt-0">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between w-100"
          >
            <router-link to="/">
              <img
                class="cursor-pointer"
                :width="isMd ? '60px' : '100px'"
                alt="Logo"
                :src="require('@/assets/images/global/logo.png')"
              />
            </router-link>

            <!-- social -->
            <div class="d-flex align-center justify-end w-100 mt-3 px-1">
              <template v-for="(social, i) in socials">
                <div
                  v-if="settings[i + 1] && settings[i + 1].is_active"
                  class="mx-1 radius-10 d-flex align-center cursor-pointer"
                  :key="i"
                  @click="goTo(settings[i + 1].value)"
                >
                  <img
                    :width="isMd ? '20px' : '40px'"
                    :src="social.icon"
                    :alt="social.type"
                  />
                </div>
              </template>
            </div>
          </v-col>

          <!-- locations -->
          <v-col cols="6" md="4" v-for="(location, i) in locations" :key="i">
            <span class="d-block font-22 font-700 white--text"
              >{{ location.name }}:
            </span>
            <span class="d-block white--text font-18 mt-3"
              >{{ $t("global.phoneNumber") }}: {{ location.phone }}</span
            >
            <div class="d-flex align-center mt-1">
              <img
                class="white-filter"
                height="20px"
                width="20px"
                :src="require('@/assets/images/icons/location.svg')"
                alt=""
              />
              <a :href="location.map_link" target="_blank">
                <span
                  class="d-block mb-1 lightText--text cursor-pointer text-underline font-18 mx-2"
                >
                  {{ location.address }}</span
                >
              </a>
            </div>
          </v-col>
          <v-col cols="6" md="4">
            <a
              class="d-block mb-1 lightText--text cursor-pointer text-underline font-18 mx-2"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe2hq20N3IVqJtgVzDA5JTJosv9b-4QL6Dqz-jrcDhYuFlzNA/viewform"
              target="_blank"
            >
              {{$t('global.googleForm')}}</a
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="copyright py-5 text-center">
      <div class="max-width">
        <span class="d-block white--text font-18">
          Master Chiken © {{ yearDate }} All Rights Reserved
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    socials: [
      {
        icon: require("@/assets/images/social/facebook.svg"),
        type: "facebook",
      },
      {
        icon: require("@/assets/images/social/twitter.svg"),
        type: "twitter",
      },
      {
        icon: require("@/assets/images/social/insta.svg"),
        type: "insta",
      },
    ],
    locations: [],
    contactDialog: false,
  }),
  computed: {
    ...mapGetters(["translate", "settings"]),
    yearDate() {
      return new Date().getFullYear();
    },
    lang() {
      return localStorage.getItem("language");
    },
  },
  methods: {
    goTo(link) {
      window.open(link, "_blank");
    },
    phoneClicked(tel) {
      window.open("tel:" + tel);
    },
    mailClicked(mail) {
      window.open(`mailto:${mail}?subject=subject&body=body`);
    },
    async getData() {
      let res = await this.$axios.get("frontend/locations");
      if (res.data) {
        this.locations = res.data;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.footer {
  .footer-app {
    padding: 50px 0px 30px 0px;
    background: #1f2227;
    z-index: 8;
  }
  .copyright {
    background: #2e333b;
  }
  @media (min-width: 960px) {
    .middle-location {
      border-left: 1px solid rgba(255, 255, 255, 0.397);
      border-right: 1px solid rgba(255, 255, 255, 0.397);
    }
  }
}
</style>
