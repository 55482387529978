<template>
  <div class="select-location">
    <!-- <div class="select-location__side-left">
      <img
        class="object-cover"
        height="100%"
        :src="require('@/assets/images/global/side-left.jpg')"
      />
    </div>

    <div class="select-location__side-right">
      <img
        class="object-cover"
        height="100%"
        :src="require('@/assets/images/global/side-right.jpg')"
      />
    </div> -->

    <div class="select-location__content">
      <div class="text-center mb-3">
        <img
          width="100px"
          :src="require('@/assets/images/global/logo.png')"
          alt=""
        />

        <span class="font-20 d-block mb-6">{{ $t('global.selectLocation') }}</span>
      </div>
      <v-row justify="center"  >
        <v-col
          v-for="(location, i) in locations"
          :key="i"
          cols="6"
          sm="4"
          md="3"
          style="flex-direction:column"
          @click="$emit('selectedLocation', location.id)"
        >
          <div class="cursor-pointer select-location__cover h-100">
            <img
              class="radius-5 mx-auto object-cover"
              :src="location.image"
              width="100%"
              :height="isMd ? '140px': '240px'"
              alt=""
            />
            <div class="cursor-pointer text-center">
              <span class="font-16 black--text d-block mb-2">{{ location.name }}</span>
              <span class="font-16 black--text d-block mb-2">{{ location.phone }}</span>
              <span class="font-16 black--text ">{{ location.address }}</span>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex align-center justify-center w-100 mt-10" v-if="settings">
        <a v-if="settings[1]" :href="settings[1].value" class="mx-4" target="_blank">
          <img
            width="30px"
            :src="require('@/assets/images/icons/facebook.png')"
          />
        </a>
        <a v-if="settings[3]" :href="settings[3].value" class="mx-4" target="_blank">
          <img
            width="30px"
            :src="require('@/assets/images/icons/instagram.png')"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    locations: [],
  }),
  computed: {
    ...mapGetters(["settings"]),
  },
  methods: {
    async getData() {
      let { data } = await this.$axios.get("frontend/locations");
      this.locations = data.map((item) => {
        return {
          ...item,
          image: item.relationships.images[0]
            ? item.relationships.images[0].path
            : null,
        };
      });
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.select-location {
  width: 100%;
  background: white;
  padding: 30px;
  display: flex;
  justify-content: center;

  &__side-left {
    width: 20vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  &__side-right {
    width: 20vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &__content {
    max-width: 1100px;
    width: 100%;
  }

  &__cover {
    border: 1px solid rgba($lightgreyText,0.6);
    border-radius: 4px;
    padding: 10px;
  }
}
</style>
