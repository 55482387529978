<template>
  <div class="cart-list">
    <v-navigation-drawer
      v-model="open"
      width="480px"
      height="100svh"
      :right="language != 'ar'"
      :left="language == 'ar'"
      :key="'card' + renderCart"
      fixed
      temporary
    >
      <div class="pa-4 cart-list__height">
        <div class="d-flex align-center justify-space-between mb-5">
          <span class="font-20 font-500 underline d-inline-block">{{
            $t("global.cart")
          }}</span>
          <div class="d-flex align-center">
            <v-icon class="mx-2" color="primary" @click="resetCart"
              >mdi-delete</v-icon
            >
            <v-icon @click="open = false">mdi-close</v-icon>
          </div>
        </div>
        <div class="cart-list__items over-scroll">
          <div class="text-center my-4">
            <span class="font-16" v-if="cartList.length == 0">{{
              $t("global.cartEmpty")
            }}</span>
          </div>
          <div
            class="cart__item d-flex align-center justify-space-between mb-6"
            v-for="(item, i) in cartList"
            :key="i"
          >
            <div class="d-flex">
              <img :src="item.images[0] ? item.images[0].path : ''" alt="" />
              <div class="mx-3">
                <span class="font-16 uppercase d-block cart-title">{{
                  item.name
                }}</span>
                <span class="font-16 uppercase d-block"
                  >{{ item.price }} TL</span
                >
                <div class="d-flex align-center">
                  <span
                    class="font-12 uppercase d-inline-block"
                    v-for="(feature, i) in item.features"
                    :key="i"
                    >({{ feature }})
                    <span v-if="i < item.features.length - 1">,</span>
                  </span>
                </div>
              </div>
            </div>

            <div style="min-width: 100px">
              <div class="text-right px-1">
                <v-icon @click="deleteItem(i)">mdi-close</v-icon>
              </div>
              <base-counter
                :value="item.count"
                @changeCount="updateHandler($event, i)"
              ></base-counter>
            </div>
          </div>
        </div>

        <div class="d-flex align-center justify-space-between mt-5">
          <span class="font-18 uppercase underline font-600"
            >{{ $t("global.total") }}:
          </span>
          <span class="font-24 font-600">{{ totalPrice.toFixed(2) }} TL</span>
        </div>
        <!-- btn -->
        <v-btn
          class="cart-list__btn"
          color="primary"
          height="50px"
          elevation="0"
          @click="$router.push('/request-details')"
        >
          <span class="white--text font-18 uppercase">{{
            $t("global.completeRequest")
          }}</span>
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    // open:{
    //     type: Boolean,
    //     default: false
    // }
  },
  data: () => ({
    open: false,
    totalPrice: 0,
    cartList: JSON.parse(localStorage.getItem("cartList")) || [],
  }),
  computed: {
    ...mapGetters(["renderCart"]),
    language() {
      return localStorage.getItem("language");
    },
    locationID() {
      return localStorage.getItem("locationID");
    },
  },
  watch: {
    renderCart: {
      async handler() {
        await this.getData();
        this.checkTotalPrice();
      },
    },
  },
  methods: {
    deleteItem(i) {
      this.cartList.splice(i, 1);
      localStorage.setItem("cartList", JSON.stringify(this.cartList));
      this.$store.dispatch("setCartList", this.cartList);
      this.checkTotalPrice();
    },
    updateHandler($event, i) {
      this.cartList[i].count = $event;
      this.$store.dispatch("setCartList", this.cartList);
      this.checkTotalPrice();
    },
    checkTotalPrice() {
      this.totalPrice = 0;

      this.cartList.forEach((item) => {
        this.totalPrice += item.count * item.price;
      });
    },
    openHandler() {
      this.open = true;
    },
    resetCart() {
      this.$store.dispatch("setCartList", []);
      localStorage.removeItem("cartList");
      this.cartList = []
      this.open = false;
    },
    async getData() {
      this.cartList = JSON.parse(localStorage.getItem("cartList")) || [];
      let res = await this.$axios.get(
        `frontend/products_without_img?location_id=${this.locationID}`
      );
      if (res.data) {
        this.cartList = this.cartList.filter((item) =>
          res.data.some((product) => item.id == product.id && product.is_active)
        );
        
        this.$store.dispatch("setCartList", this.cartList);
        setTimeout(() => {
          localStorage.setItem("cartList", JSON.stringify(this.cartList));
        }, 1000);
      }
    },
  },
  created() {
    this.$parent.$on("openCart", this.openHandler);
    setTimeout(async () => {
      await this.getData();
    }, 300000);
  },
  mounted() {
    setTimeout(async () => {
      await this.checkTotalPrice();
    }, 500);
  },
};
</script>

<style lang="scss">
.cart-list {
  z-index: 99;
  position: fixed;
  top: 0px;
  background: white;
  
  &__height{
    height: 100vh !important;
    position: relative;
  }
  .cart-title {
    line-height: 1.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 131px;
  }
  &__items {
    height: 64svh !important;
    min-height: 64svh !important;
  }

  &__item {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &__btn {
    margin-top: 20px;
    width: 100%;
  }

  @media (max-width: 960px) {

    &__items {
      height: 58svh !important;
    }
  }
}

@keyframes scaleCart {
  0% {
    transform: scale(1);
    color: grey;
  }
  50% {
    transform: scale(1.5);
    color: $primary;
  }
  100% {
    transform: scale(1);
    color: grey;
  }
}
</style>
