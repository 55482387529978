<template>
  <div
    class="base-input base-select"
    :class="
      value.length > 2
        ? 'base-select-mulitple'
        : value.length == 2
        ? 'base-select-mulitple-two'
        : ''
    "
  >
    <v-select
      :items="items"
      outlined
      :placeholder="placeholder"
      dense
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :rules="rules"
      :multiple="multiple"
      background-color="graybg"
      @input="$emit('input', $event)"
      color="purple"
    >
      <template v-slot:selection="data">
        <span class="mr-2">
          {{
            value.length > 2 && !isString
              ? value.length + " " + translate["subject.selected"]
              : value.length >= 1
              ? data.item[itemText] + " "
              : translate["subject.pleaseSelect"]
          }}
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isString: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["translate"]),
  },
};
</script>

<style lang="scss">
.base-select {
  position: relative;

  .v-input__slot {
    border-radius: 10px;
    .v-input__append-inner {
      margin-top: 10px !important;
    }
    fieldset {
      height: 48px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }

    .v-text-field__slot {
      height: 48px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 48px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
  .v-select--is-multi .v-select__selections {
    span {
      display: none;

      &:nth-child(1) {
        display: block;
      }
    }
  }
}
.base-select-mulitple-two {
  .v-select__selections {
    span {
      display: none;

      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
}
</style>
