<template>
  <div class="cart">
    <div class="d-flex align-center">
      <v-icon
        class="mx-2 cursor-pointer mt-0"
        @click="$router.push('/my-favorite')"
        size="20px"
        >mdi-heart-outline</v-icon
      >

      <div class="relative" v-if="$route.name != 'request-details'">

        <v-icon
        class="mx-2 cursor-pointer mt-0 cart-icon"
        :key="renderCart"
        @click="$emit('click')"
        :color="cartList.length > 0 ? 'primary' : ''"
        :size="cartList.length > 0 ? '30px' : '20px'"
        >mdi-cart</v-icon
        >
        <span class="cart-length" v-if="cartList.length > 0">{{ cartList.length }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    open: false,
    totalPrice: 0,
  }),
  computed: {
    ...mapGetters(["renderCart", "cartList"]),
    language() {
      return localStorage.getItem("language");
    },
  },
  watch: {
    renderCart: {
      handler() {
      this.cartList = JSON.parse(localStorage.getItem("cartList")) || [];
        this.checkTotalPrice();
      },
    },
  },
  methods: {
    openHandler() {
      this.open = true;
    },
    checkTotalPrice() {
      this.totalPrice = 0;

      this.cartList.forEach((item) => {
        this.totalPrice += item.count * item.price;
      });
    },
  },
  mounted() {
    setTimeout(async () => {
      await this.checkTotalPrice();
    }, 500);
  },
};
</script>

<style lang="scss">
.cart {
  .cart-title {
    line-height: 1.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 131px;
  }

  &__item {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &__btn {
    position: absolute !important;
    bottom: 60px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }

  .cart-icon {
    transform: scale(1);
    color: grey;
    animation: scaleCart 1.5s forwards;
  }
  .cart-length{
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 22px;
    height: 22px;
    line-height: 21px;
    border-radius: 50%;
    background: rgb(112, 112, 112);
    color: white;
    font-size: 14px;
    text-align: center;
  }

  @media (max-width: 960px) {
    &__btn {
      bottom: 100px;
    }
  }
}

@keyframes scaleCart {
  0% {
    transform: scale(1);
    color: grey;
  }
  50% {
    transform: scale(1.5);
    color: $primary;
  }
  100% {
    transform: scale(1);
    color: grey;
  }
}
</style>
