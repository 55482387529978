<template>
  <div class="relative w-100 search-input">
    <v-autocomplete
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      auto-select-first
      clearable
      solo
      hide-details
      item-text="name"
      item-value="id"
      prepend-inner-icon="mdi-magnify"
      append-icon=""
      color="primary"
      :placeholder="$t('global.searchProduct')"
      @update:search-input="searchHandler"
    >
      <template v-slot:no-data>
        <v-list-item v-if="search">
          <v-list-item-title>
            No Result for
            <strong>{{ search }}</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:item="data">
        <div class="d-flex align-center w-100" @click="openHandler(data.item)">
          <v-list-item-avatar>
            <img
              width="55px"
              height="55px"
              class="object-cover circle"
              :src="data.item.image"
              alt=""
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.name"></v-list-item-title>
            <v-list-item-subtitle
              v-text="data.item.note"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-arrow-right</v-icon>
          </v-list-item-action>
        </div>
      </template>
    </v-autocomplete>

    <v-dialog
      v-model="productDialog"
      max-width="1100px"
      :content-class="`white     ${
        isMd ? 'radius-10 pa-2' : 'radius-20 pa-5'
      }'`"
    >
      <product-details
        @close="productDialog = false"
        :item="selectedItem"
      ></product-details>
    </v-dialog>
  </div>
</template>

<script>
import { searchProduct } from "@/services/Homepage";
import ProductDetails from "@/components/dialogs/ProductDetails.vue";
import { fetchProduct } from "@/services/Products";

export default {
  components: { ProductDetails },

  data: () => ({
    search: "",
    isLoading: false,
    items: [],
    searchResult: [],
    productDialog: false,
    selectedItem: {},
  }),
  computed: {
    locationID() {
      return localStorage.getItem("locationID");
    },
  },
  methods: {
    goTo() {},
    async openHandler(item) {
      // if (!item.is_active) {
      //   return;
      // }
      let searchResult =
        JSON.parse(localStorage.getItem("searchedProduct")) || [];
      if (searchResult.length > 0) {
        let existItem = searchResult.find(i => i.id == item.id)
        if(!existItem){
          searchResult.unshift(item);
        }
        if (searchResult.length > 5) {
          searchResult = searchResult.slice(0, 5);
        }
      } else {
        searchResult.push(item);
      }
      localStorage.setItem("searchedProduct", JSON.stringify(searchResult));
      this.selectedItem = await fetchProduct(item.id, this.locationID);
      if (this.selectedItem.id) {
        this.productDialog = true;
      }
    },
    async searchHandler() {
      if (this.search && this.search.length > 0) {
        this.items = [];
        this.isLoading = true;
        let res = await searchProduct(this.search);
        this.items = [...res];
        this.isLoading = false;
      } else {
        this.searchResult = JSON.parse(localStorage.getItem("searchedProduct"));
        if (this.searchResult.length > 0) {
          this.items = this.searchResult;
        }else{
          this.items = [];
        }
      }
    },
  },
  created() {
    this.searchResult = JSON.parse(localStorage.getItem("searchedProduct"));
    if (this.searchResult.length > 0) {
      this.items = this.searchResult;
    }
  },
};
</script>

<style lang="scss">
.search-input {
  max-width: 500px;
  margin-left: 120px;
  .v-list-item {
    height: 80px;
  }
}
</style>
