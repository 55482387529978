import axios from "axios";

export const searchProduct = async (key) => {
  let params = {
    return_from_search: "images_name_price",
    keyword: key,
    order_field: "name",
    order_type: "DESC",
    location_id: localStorage.getItem('locationID'),
    limit: 5,
  };
  let { data } = await axios.get(`frontend/search_product`, { params });
  if (data) {
    data = data.map((item) => {
        return {
          ...item,
          price: item.relationships && item.relationships.productprices[0] ? item.relationships.productprices[0].discountPrice : '',
          image:
            item.relationships && item.relationships.images[0]
              ? item.relationships.images[0].path
              : null,
        };
      });
    return data;
  }
};
