import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    translate: {},
    settings: {},
    locationDetails: {},
    locationID: "",
    renderCart: false,
    cartList: []
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    translate(state) {
      return state.translate;
    },
    locationID(state) {
      return state.locationID;
    },
    renderCart(state) {
      return state.renderCart;
    },
    cartList(state) {
      return state.cartList;
    },
    settings(state) {
      return state.settings;
    },
    locationDetails(state) {
      return state.locationDetails;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_SHOWNAVBAR(state, payload) {
      state.showNavbar = payload;
    },
    SET_TRANSLATE(state, payload) {
      state.translate = payload;
    },
    SET_LOCATION(state, payload) {
      state.locationID = payload;
    },
    SET_RENDER_CART(state) {
      state.renderCart = !state.renderCart;
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    },
    SET_LOCATION_DETAILS(state, payload) {
      state.locationDetails = payload;
    },
    SET_CART_LIST(state, payload) {
      state.cartList =payload;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    setTranslate({ commit }, payload) {
      commit("SET_TRANSLATE", payload);
    },
    setLocationID({ commit }, payload) {
      commit("SET_LOCATION", payload);
    },
    renderCart({ commit }) {
      commit("SET_RENDER_CART");
    },
    setCartList({ commit }, payload) {
      commit("SET_CART_LIST", payload);
    },
    setSettings({ commit }, payload) {
      commit("SET_SETTINGS", payload);
    },
    setLocationDetails({ commit }, payload) {
      commit("SET_LOCATION_DETAILS", payload);
    },
  },
  modules: {},
});
