<template>
  <div class="product-details relative">
    <div class="product-details__close-icon cursor-pointer z-9">
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
      />
    </div>
    <v-row class="px-0 mx-0" align="center">
      <v-col cols="12" md="5">
        <slick-carousel class="relative" v-bind="largeSlide">
          <div
            class="product-details__img d-flex align-center my-4"
            v-for="(item, index) in item.images"
            :key="index"
          >
            <img
              class="object-cover large-slide__item__img radius-5"
              width="100%"
              height="100%"
              :src="item.path"
            />
          </div>
          <template #prevArrow>
            <v-icon
              class="custom-slick-arrow custom-slick-arrow--prev cursor-pointer"
              >mdi-chevron-left</v-icon
            >
          </template>
          <template #nextArrow>
            <v-icon
              class="custom-slick-arrow custom-slick-arrow--next cursor-pointer"
              >mdi-home</v-icon
            >
          </template>
        </slick-carousel>
      </v-col>
      <v-col
        cols="12"
        md="7"
        class="pb-10"
        :class="isMd ? 'py-3 px-7' : 'pa-5'"
      >
        <div>
          <div class="d-flex align-center justify-space-between">
            <span class="uppercase font-24 font-600 d-block mb-2">{{
              item.name
            }}</span>
            <base-counter
              v-if="isMd"
              :value="itemCount"
              @changeCount="changeCountHandler"
            ></base-counter>
          </div>
          <span class="font-600 font-20"
            >{{ $t("global.price") }}:
            {{
              item.has_discount
                ? item.discountPrice.toFixed(2)
                : item.regularPrice.toFixed(2)
            }}
            TL</span
          >
        </div>
        <div v-for="(feature, i) in features" :key="i">
          <span class="font-20 d-block mb-1 black--text">{{
            feature["attribute_name_" + lang]
          }}</span>
          <v-radio-group v-model="selectedFeature[i]" row>
            <v-radio
              v-for="(option, j) in feature.attribute_values"
              :key="j"
              class="feature-label"
              :label="
                option['attribute_value_' + lang] +
                ' (' +
                option.attribute_price +
                ') TL'
              "
              :value="handleOption(option, feature['attribute_name_' + lang])"
            ></v-radio>
            <v-radio
              class="feature-label"
              :label="$t('global.none')"
              value=""
            ></v-radio>
          </v-radio-group>
        </div>

        <div class="mt-2">
          <base-counter
            v-if="!isMd"
            :value="itemCount"
            class="mt-4"
            @changeCount="changeCountHandler"
          ></base-counter>

          <base-textarea
            class="mt-3"
            v-model="userNotes"
            :placeholder="$t('global.writeYourNotes')"
            title=""
            :rules="[]"
          ></base-textarea>

          <span
            class="font-18 primary--text font-500 d-block mt-2"
            v-if="item.note"
            >( {{ item.note }} )</span
          >
        </div>

        <div :class="isMd ? '' : 'd-flex align-center'">
          <div
            class="product-card__btns__btn text-center justify-center py-4 mt-7 radius-5"
            :class="isMd ? 'w-100' : lang == 'ar' ? 'ml-3' : 'mr-3'"
            @click="addHandler"
          >
            <span class="white--text font-18 uppercase">{{
              $t("global.addToCart")
            }}</span>
          </div>
          <div
            class="product-card__btns__btn text-center justify-center py-4 radius-5"
            :class="
              isMd ? 'w-100 mt-4' : lang == 'ar' ? 'mr-3 mt-7' : 'ml-3 mt-7'
            "
            @click="sendRequest"
            v-if="settings[8].value != '0'"
          >
            <span class="white--text font-18 uppercase">{{
              $t("global.sendOrder")
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="px-7" v-if="item.description">
        <div class="pa-4 radius-5 lightgreybg mb-10">
          <p v-html="item.description"></p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    requestType: {
      type: String,
      default: "sparis",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    largeSlide: {
      touchMove: false,
      speed: 1000,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      autoplay: true,
      dots: false,
      fade: true,
    },
    userNotes: "",
    selectedFeature: [],
    features: [],
    itemCount: 1,
  }),
  computed: {
    ...mapGetters(["locationDetails", "settings"]),
    locationID() {
      return localStorage.getItem("locationID");
    },
    lang() {
      return localStorage.getItem("language") || "en";
    },
  },
  methods: {
    handleOption(option, featureName) {
      option.featureName = featureName;
      return option;
    },
    changeCountHandler(val) {
      this.itemCount = +val;
    },
    addHandler() {
      this.item.features = [];
      this.item.price = this.item.has_discount
        ? this.item.discountPrice
        : this.item.regularPrice;
      this.item.count = this.itemCount;
      let cartList = [];

      let storageList = JSON.parse(localStorage.getItem("cartList"));
      if (storageList) {
        cartList = storageList;
      }
      this.item.selectedFeaturePrice = []
      this.selectedFeature.forEach((option,i) => {
        if (option) {
          this.item.features.push(
            option.featureName + `: ${option["attribute_value_" + this.lang]} `
          );
          this.item.price += +option.attribute_price;
          this.item.selectedFeaturePrice[i] = option.attribute_price;
        }
      });

      cartList.push(this.item);
      localStorage.setItem("cartList", JSON.stringify(cartList));
      this.$store.dispatch("renderCart");
      this.$store.dispatch("setCartList", cartList);
      this.$store.dispatch("showSnack", {
        text: this.$t("global.itemAddedToCart"),
        color: "success",
      });
      this.$emit("exit");
    },

    async sendRequest() {
      if (this.item.count == 0) {
        return;
      }
      if (this.item.regularPrice * +this.itemCount < +this.settings[9].value) {
        this.$store.dispatch("showSnack", {
          text: this.$t("global.orderLimit") + this.settings[9].value,
          color: "error",
        });
        return;
      }
      this.item.features = [];
      this.item.price = this.item.has_discount
        ? this.item.discountPrice
        : this.item.regularPrice;

      let whatsappMessage = "";
      let cartList = [this.item];

      this.selectedFeature.forEach((option) => {
        if (option) {
          this.item.price += +option.attribute_price;
        }
      });

      cartList.forEach((item) => {
        whatsappMessage += `*${item.serial}* - ${item.name} ${+this
          .itemCount} * ${+item.price} = ${+this.itemCount * +item.price}  %0a`;
      });

      this.selectedFeature.forEach((option) => {
        if (option) {
          this.item.features.push(
            option.featureName +
              `: (${option["attribute_value_" + this.lang]}) `
          );
          whatsappMessage +=
            option.featureName +
            `: (${option["attribute_value_" + this.lang]}) , `;
        }
      });

      whatsappMessage += "%0a";
      whatsappMessage += this.selectedFeature.length > 0 ? "%0a" : "";

      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;
      whatsappMessage += `%0a`;

      whatsappMessage += this.userNotes
        ? `${this.$t("global.userNotes")}: ${this.userNotes}%0a`
        : "";

      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;
      whatsappMessage += `%0a`;

      whatsappMessage += `image_link `;

      let req = {
        products: cartList.map((item) => {
          return {
            product_id: item.serial,
            name: item.name,
            price: +item.price,
            amount: +this.itemCount,
            total: +this.itemCount * +item.price,
          };
        }),
        is_delivery: 0,
        general_total_price: +this.itemCount * +this.item.price,
        coupon_value: "",
        total_price: +this.itemCount * +this.item.price,
        products_amount: +this.itemCount,
      };
      this.$axios.defaults.baseURL = "https://api.master-chicken.com/v1/";
      let res = await this.$axios.get(
        "frontend/checkout",
        {
          params: {
            invoice_data: JSON.stringify(req),
            location_id: this.locationID,
            message: whatsappMessage,
            location_phone: this.locationDetails.phone,
            lang: this.lang,
          },
        },
        {
          headers: {
            lang: this.lang,
          },
        }
      );
      this.$axios.defaults.baseURL = "https://api.master-chicken.com/api/v1/";
      if (res.request.status == 200) {
        window.open(res.request.responseURL, "_open");
        localStorage.removeItem("cartList");
        this.$emit("exit");
      }
    },
  },
  created() {
    this.features = JSON.parse(this.item.productattributes);
    this.itemCount = this.item.count;
  },
};
</script>

<style lang="scss">
.product-details {
  overflow: hidden;
  &__close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
      height: 25px;
    }
  }

  &__img {
    height: 450px;
  }
  .custom-slick-arrow {
    z-index: 9;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    position: absolute !important;
    transform: unset !important;
    &--prev {
      left: 0%;
    }
    &--next {
      right: 0%;
    }
  }
  .feature-label {
    label {
      font-size: 18px;
    }
  }

  @media (max-width: 960px) {
    &__close-icon {
      top: 10px;
      right: 10px;
      background: white;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      img {
        width: 17px;
        height: 17px;
      }
    }
    &__img {
      height: 250px;
    }
    .slick-next:before {
      width: 10px;
      height: 10px;
    }
    .feature-label {
      label {
        font-size: 12px;
      }
    }
  }
}
</style>
