<template>
  <div class="drawer">
    <v-app-bar
      prominent
      height="70px"
      class="w-100 d-flex align-center justify-space-between drawer-navbar mb-1"
      elevation="0"
    >
      <div class="d-flex align-center">
        <img
          class="cursor-pointer"
          :width="isMd ? '50px' : '100px'"
          @click="redirectHandler"
          :src="require(`@/assets/images/global/logo.png`)"
        />
        <span class="font-26 d-inline-block mx-4">{{
          locationDetails.name
        }}</span>
      </div>
      <div class="d-flex align-center justify-end">
        <span class="change-location" @click="resetLocation">{{
          $t("global.changeLocation")
        }}</span>
        <!-- languages -->
        <languages
          :selectedLanguage="selectedLanguage"
          @switchLanguage="switchLanguage"
        ></languages>

        <!-- cart -->
        <cart-icon-app @click="openCartHandler"></cart-icon-app>
      </div>
    </v-app-bar>

    <cart-list></cart-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Languages from "./components/Languages.vue";
import CartIconApp from "./components/CartIcon.vue";
import CartList from "./components/CartList.vue";

export default {
  components: { Languages, CartIconApp, CartList },
  data: () => ({
    drawer: false,
    links: [],
    group: "",
    menuGroup: "",
  }),
  computed: {
    ...mapGetters(["locationDetails"]),
    selectedLanguage() {
      if (this.$vuetify.rtl) {
        return "arabic";
      } else {
        return "english";
      }
    },
  },
  methods: {
    openCartHandler() {
      this.$emit("openCart");
    },
    resetLocation() {
      localStorage.removeItem("locationID");
      localStorage.removeItem("cartList");
      this.$router.go();
    },
    switchLanguage(language) {
      localStorage.setItem("language", language.value);
      this.$router.replace({ query: { lang: "" } });
      this.$router.go("");
    },
    redirectHandler() {
      if (this.$route.name == "Homepage") {
        this.resetLocation();
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}
.drawer {
  z-index: 9999999999999999999 !important;
  position: relative;

  .drawer-navbar {
    background: transparent !important;
    transition: 0.3s;
  }
  .logo {
    width: 110px !important;
  }
  .v-list {
    background: transparent !important;
    i {
      color: white;
    }
  }
  .v-list-group__header {
    padding-left: 8px !important;
    i {
      color: white !important;
    }
  }
  .primary-outlined {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
}
</style>
