import Vue from "vue";
import VueI18n from "vue-i18n";
import AR from "./ar";
import EN from "./en";
import TR from "./tr";
Vue.use(VueI18n);


let checkLang = () => {
  if (localStorage.getItem("language")) {
    return localStorage.getItem("language");
  } else {
    let lang = navigator.language || navigator.userLanguage;
    if (lang.indexOf("en") > -1) lang = "en";

    localStorage.setItem("language", lang);
    return lang;
  }
};

let lang = checkLang();


export default new VueI18n({
  silentFallbackWarn: true,
  lazy:true,
  locale: lang,
  fallbackLocale: "ar",
  messages: {
    en: EN,
    ar: AR,
    tr: TR,
  },
});
