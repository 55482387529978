<template>
  <div :class="rounded ? '' : 'search-style'">
    <v-text-field
      :value="value"
      @input="$emit('input', $event)"
      :placeholder="translate['global.search']"
      :background-color="background"
      :color="color"
      class="mt-2 radius-10"
      outlined
      :rounded="rounded"
      :hide-details="true"
      dense
      :rules="[]"
      height="50px"
      filled
    >
      <template v-slot:append v-if="!leftIcon">
        <div class="d-flex align-center mt-1">
          <img
            :src="
              require(`@/assets/images/icons/search${
                isPrimary ? '' : '-black'
              }.svg`)
            "
            alt=""
          />
        </div>
      </template>
      <template v-slot:prepend-inner v-if="leftIcon">
        <div class="d-flex align-center mt-1 mr-2">
          <!-- <img
            :src="require(`@/assets/images/icons/search${isPriamry ? '': '-black'}.svg`)"
            alt=""
          /> -->
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "#f5f5f5",
    },
    color: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    leftIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["translate"]),
  },
};
</script>

<style lang="scss">
.search-style {
    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.05) !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        border: 1px solid #e44358 !important;
      }
  }
}
</style>
