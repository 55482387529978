const routes = [
  {
    path: "/",
    name: "Homepage",
    component: () => import("../views/homepage/Index.vue"),
  },
  {
    path: "/category",
    name: "Category",
    component: () => import("../views/products/Index.vue"),
  },
  {
    path: "/category/:title",
    name: "Category",
    component: () => import("../views/products/Index.vue"),
  },
  {
    path: "/500",
    name: "server-error",
    component: () => import("../views/500/Index"),
  },
  {
    path: "/my-favorite",
    name: "my-favorite",
    component: () => import("../views/favorite/Index"),
  },
  {
    path: "/request-details",
    name: "request-details",
    component: () => import("../views/request-details/Index"),
  },
  {
    path: "*",
    name: "error-page",
    component: () => import("../views/not-found/Index"),
  },
];

export default routes;
