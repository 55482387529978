import Vue from "vue";
import axios from "axios";
import store from "../store";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";

Vue.prototype.$baseURL = "https://api.master-chicken.com/";
axios.defaults.baseURL = `${Vue.prototype.$baseURL}api/v1/`;

window.axios = axios;
Vue.prototype.$axios = axios;

let checkLang = () => {
  if (localStorage.getItem("language")) {
    return localStorage.getItem("language");
  } else {
    let lang = navigator.language || navigator.userLanguage;
    if (lang.indexOf("en") > -1) lang = "en";
    else if (lang.indexOf("ar") > -1) lang = "ar";
    else lang = "tr";

    localStorage.setItem("language", lang);
    return lang;
  }
};

let lang = checkLang();

const html = document.documentElement;
const body = document.querySelector("body");
localStorage.setItem("language", lang);
Vuetify.rtl = lang == "ar" ? true : false;
VueI18n.locale = lang;
html.setAttribute("lang", lang);
body.setAttribute("dir", lang == "ar" ? true : false);

axios.defaults.headers = {
  accept: "application/json",
  "Accept-Language": checkLang(),
};

export default async () => {
  // show loader in each request
  window.axios.interceptors.request.use(
    (confiq) => {
      store.dispatch("loading", true);
      return confiq;
    },
    (error) => {
      return error;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      store.dispatch("loading", false);
      return response;
    },
    async function (error) {
      if (error.response.status == 401) {
        store.dispatch("logout");
      }
      store.dispatch("loading", false);
      // response with status code > 200 is here
      return error.response.data;
    }
  );
};
