<template>
  <div class="input-style relative" :class="type== 'number' ?'base-phone' : ''">
    <v-text-field
      class="w-100"
      :type="type"
      :outlined="outlined"
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      @input="$emit('input', $event)"
      :rounded="rounded"
      :append-icon="customIcon ? appendIcon : type == 'email' && hasValue ? 'mdi-check' :''"
      full-width
      :readonly="readonly"
      color="black"
      @click:append="$emit('appendClicked')"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "text",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    border-radius: 5px;
    fieldset {
      height: 55px !important;
      border: 1px solid rgba(0,0,0,.1) !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-input__append-inner {
      margin-top: 5px ;
    }
    .v-text-field__slot {
      height: 50px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 55px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}
.base-phone input::-webkit-outer-spin-button,
.base-phone input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.base-phone input[type="number"] {
  -moz-appearance: textfield !important;
}
.v-icon--link{
  margin-top: 10px
}
.v-text-field__details{
  margin-bottom: 0px !important
}
</style>
