<template>
  <div
    class="navbar"
    :class="
      $route.name != 'Homepage'
        ? bannerAccepted
          ? 'navbar--fixed'
          : 'navbar--with-topbanner'
        : ''
    "
  >
    <div class="transition-03 navbar--scrolled"></div>
    <drawer-app v-if="isMd"></drawer-app>
    <div
      class="navbar__max-width max-width hidden-sm-and-down z-9 py-2"
      v-if="!isMd"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center h-100">
          <!-- logo -->
          <div class="d-flex align-center">
            <div class="navbar__logo">
              <img
                class="cursor-pointer"
                :width="isMd ? '30px' : '60px'"
                :height="isMd ? '30px' : '60px'"
                alt="Logo"
                @click="redirectHandler"
                :src="require('@/assets/images/global/logo.png')"
              />
            </div>

            <span class="font-26 d-inline-block mx-4">{{ locationDetails.name }}</span>
          </div>
        </div>

        <search-input></search-input>
        <!-- buttons logged -->
        <transition name="fade" mode="out-in">
          <div class="d-flex align-center justify-end">
            <span class="change-location" @click="resetLocation">{{
              $t("global.changeLocation")
            }}</span>
            <!-- languages -->
            <languages @switchLanguage="switchLanguage"></languages>

            <!-- cart -->
            <cart-icon-app @click="openCartHandler"></cart-icon-app>
          </div>
        </transition>
      </div>
    </div>

    <cart-list :key="openCart"></cart-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchInput from "./components/SearchInput.vue";
import Languages from "./components/Languages.vue";
import CartIconApp from "./components/CartIcon.vue";
import CartList from "./components/CartList.vue";
import DrawerApp from "./Drawer.vue";
export default {
  components: {
    DrawerApp,
    SearchInput,
    Languages,
    CartIconApp,
    CartList,
  },
  data: () => ({
    obj: {},
    openCart: false,
    type: "",
  }),
  computed: {
    ...mapGetters(["showNavbar", "translate", "settings", "locationDetails"]),
    locationID() {
      return localStorage.getItem("locationID");
    },
    bannerAccepted() {
      return localStorage.getItem("banner");
    },
  },
  methods: {
    openCartHandler() {
      this.$emit("openCart");
    },
    switchLanguage(language) {
      localStorage.setItem("language", language.value);
      this.$router.go("");
    },
    resetLocation() {
      localStorage.removeItem("locationID");
      localStorage.removeItem("cartList");
      this.$router.go();
    },
    async getLocationDetails() {
      let res = await this.$axios.get(
        `frontend/current_location?location_id=${this.locationID}`
      );
      if (res.data) {
        this.$store.dispatch("setLocationDetails", res.data);
      }
    },
    redirectHandler() {
      if (this.$route.name == "Homepage") {
        this.resetLocation();
      } else {
        this.$router.push("/");
      }
    },
  },
  async created() {
    await this.getLocationDetails();
  },
};
</script>

<style lang="scss">
.navbar {
  z-index: 9999999;
  width: 100%;
  position: relative;
  transition: 0.3s;
  background: white;

  &__max-width {
    margin: auto;
    padding: 0px 2%;
    position: relative;
  }
  &--scrolled {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    border-bottom: 1px solid #5556587e;
  }
  &--fixed {
    position: fixed;
    top: 0px;
  }
  &--with-topbanner {
    position: sticky;
    padding-top: 10px;
    top: 0px;
  }
  .language-menu {
    z-index: 9999999 !important;
    span {
      color: white;
      transition: 0.3s;
    }
  }

  .white-outline {
    border: 1px solid white;
    border-radius: 10px;
    padding: 6px 20px;
  }
  .primary-outline {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 6px 20px;
  }
}
.v-menu__content {
  z-index: 9999999999999999999999 !important;
}
.language-menu {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transform: translate(24px, 18px) !important;
  z-index: 999 !important;
  .v-list-item {
    min-height: 35px;
  }
}
.form-height {
  height: 600px !important;
}

.v-tooltip__content {
  z-index: 999999999999999 !important;
}

.change-location {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin: 0px 10px;
  font-size: 15px;
}
</style>
