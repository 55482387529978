export default {
  global: {
    english: "English",
    arabic: "العربية",
    turkish: "Türkçe",
    noItems: "No Items Found...",
    changeLocation: "Change Location",
    noCategory: "This Category is Not Available",
    noProduct: "This Product is Not Available",
    searchProduct: "Search for product",
    cartEmpty: "Your Cart is Empty...",
    cart: "Cart",
    total: "Total",
    open: "Open",
    request: "Request",
    completeRequest: "Complete Request",
    widthCoupon: "With Coupon",
    addToCart: "Add To Cart",
    sendOrder: "Send Order",
    favoriteEmpty: "Your Favorite List Is Empty...",
    seeProducts: "See Products",
    add: "Add",
    backToProducts: "Back To Products",
    yourFavoriteList: "Your Favorite List",
    writeYourNotes: "write your notes...",
    price: "Price",
    requestDetails: `Request's Details`,
    name: "Name",
    delivery: "Delivery",
    note: "order details...",
    sendByWhatsapp: "Send By Whatsapp",
    yourAddress: "your address or copy and paste your address link",
    pickUp: "Pick-Up",
    use: "Use",
    useCoupon: "Use Coupon",
    none: "None",
    enterPhone: "Enter phone number",
    totalPrice: "Total Price",
    couponAmount: "Coupon Amount",
    generalTotal: "General Total",
    productsAmount: "Products Amount",
    orderNotes: "Order Notes",
    username: "Username",
    userPhone: "User Phone",
    toAddress: "To Address",
    userAddress: "User Address",
    yes: "Yes",
    no: "No",
    stillNeed : 'Still need ',
    toUseCoupon: 'to use this Coupon',


    mustNotZero: 'Count Must Not Be 0',
    itemAddedToCart: 'New Item has been Added to Cart',
    selectLocation: 'Select Your Location',
    phoneNumber: 'Phone Number',
    nameRequire: 'Name is Required',
    googleForm: 'Join our Team:',
    orderLimit: 'The minimum total is ',
    siteIsOff: 'The website is under construction'
  },
};
