<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <SelectLocation
        v-if="
          !selectedLocationFromApp && !locationID && settings[7].value != '0'
        "
        @selectedLocation="selectLocationHandler"
      ></SelectLocation>
      <div class="text-center my-14" v-if="settings[7].value == '0'">
        <img
          width="100px"
          :src="require('@/assets/images/global/logo.png')"
          alt=""
        />
        <span class="font-24 d-block mt-4">{{ $t("global.siteIsOff") }}</span>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div
        class="app"
        v-if="
          (locationID || selectedLocationFromApp) && settings[7].value != '0'
        "
        :key="reCreateApp"
      >
        <transition name="fade" mode="out-in">
          <top-banner
            v-if="viewBanner"
            @close="viewBanner = false"
          ></top-banner>
        </transition>
        <transition name="fade" mode="out-in">
          <navbar-app @recreate="recreate"> </navbar-app>
        </transition>
        <snackbar></snackbar>
        <div></div>

        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
        <footer-app class="footer"></footer-app>
        <whatsapp-btn></whatsapp-btn>
      </div>
    </transition>
  </v-app>
</template>

<script>
import NavbarApp from "@/layout/Navbar";
import FooterApp from "@/layout/Footer";
import SelectLocation from "@/layout/SelectLocation";
import Snackbar from "@/components/global/Snackbar";
import TopBanner from "@/layout/components/TopBanner";
import WhatsappBtn from "./components/global/WhatsappBtn.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    SelectLocation,
    NavbarApp,
    Snackbar,
    FooterApp,
    TopBanner,
    WhatsappBtn,
  },
  data: () => ({
    reCreateApp: false,
    viewBanner: true,
    selectedLocationFromApp: "",
  }),
  computed: {
    ...mapGetters(["settings"]),
    locationID() {
      return (
        localStorage.getItem("locationID") || this.$store.getters["locationID"]
      );
    },
  },
  watch: {
    reCreateApp: {
      async handler() {
        await this.handleTranslate();
      },
    },
  },
  methods: {
    selectLocationHandler(val) {
      this.selectedLocationFromApp = val;
      localStorage.setItem("locationID", val);
    },
    recreate() {
      this.reCreateApp = !this.reCreateApp;
    },
    async handleTranslate() {
      const html = document.documentElement;
      const body = document.querySelector("body");

      let res = await this.$axios.get(`frontend/translates/en`);
      if (res.data) {
        // if (res.data.value.languageCode == "ar") {
        //   this.setSiteLang("ar", true, "rtl", html, body);
        // } else {
        this.setSiteLang("en", false, "ltr", html, body);
        // }
      }
      this.$store.dispatch("setTranslate", res.data);
    },
    setSiteLang(lang, rtlBoolean, rtlDir, html, body) {
      localStorage.setItem("language", lang);
      this.$vuetify.rtl = rtlBoolean;
      this.$i18n.locale = lang;
      html.setAttribute("lang", lang);
      body.setAttribute("dir", rtlDir);
    },
    async getSettings() {
      let res = await this.$axios.get("frontend/settings");
      if (res.data) {
        this.$store.dispatch("setSettings", res.data);
      }
    },
  },
  async created() {
    await this.getSettings();
    let res = JSON.parse(localStorage.getItem("cartList"));
    if (res && res.length > 0) {
      this.$store.dispatch("setCartList", res);
    }
    setTimeout(() => {}, 1000 * 20);
  },
};
</script>

<style lang="scss">
.app {
  .custom-app {
    background: #f5f5f5 !important;
  }
}
</style>
