import axios from "axios";

export const fetchProducts = async (filters) => {
  let { data } = await axios.get(`frontend/products`, { params: filters });
  data = data.map((item) => {
    return {
      ...item,
      tag: item.relationships.tag,
      image: item.relationships.image ? item.relationships.image.path : null,
    };
  });
  return data;
};

export const fetchProduct = async (id, locationID) => {
  let { data } = await axios.get(`frontend/products/${id}?location_id=${locationID}`);
  return {
    ...data,
    productprices: data.relationships.productprices,
    category: data.relationships.category,
    tag: data.relationships.tag,
    images: data.relationships.images,
  };
};
