<template>
  <div
    class="whatsapp-btn d-flex align-center justify-center cursor-pointer"
    @click="goTo"
  >
    <img
      :width="isMd ? '24px' : '36px'"
      :src="require('@/assets/images/icons/whatsapp.png')"
      alt=""
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["locationDetails"]),
  },
  methods: {
    goTo() {
      if (this.locationDetails) {
        window.open(`https://wa.me/9${this.locationDetails.phone}`);
      }
    },
  },
};
</script>

<style lang="scss">
.whatsapp-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  animation: fadeUpBtn 1s 1s forwards;
  transform: translateY(350px);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #48c350;

  img {
    filter: invert(100%) sepia(0%) saturate(7480%) hue-rotate(74deg)
      brightness(97%) contrast(105%);
  }

  @media (max-width: 960px) {
    width: 50px;
    height: 50px;
    bottom: 10px;
  }
}

@keyframes fadeUpBtn {
  0% {
    transform: translateY(350px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
