<template>
  <div class="counter d-flex align-center">
    <div>
      <v-btn color="#eee" width="17px " class="px-0" elevation="0" @click="countHandler('descrease')">
        <v-icon size="17"> mdi-minus </v-icon>
      </v-btn>
      <span class="font-18 mx-3" :key="value">{{ count }}</span>
      <v-btn color="#eee" width="17px " class="px-0" elevation="0" @click="countHandler('increase')">
        <v-icon size="17"> mdi-plus </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    count: 1,
  }),
  methods: {
    countHandler(type) {
      if (type == "descrease" && this.count > 1) {
        this.count -= 1;
      }
      if (type == "increase") {
        this.count += 1;
      }
      this.$emit("changeCount", this.count);
    },
  },
  created() {
    this.count = this.value;
  },
};
</script>

<style lang="scss">
.counter{
  button{
    min-width: 35px !important;
  }

  @media (max-width: 960px) {
    button{
      min-width: 26px !important;
    }
  }
}
</style>
