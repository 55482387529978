<template>
  <div>
    <!-- langeuages desktop-->
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center mx-1" v-bind="attrs" v-on="on">
          <img
            width="18px"
            v-if="language == 'ar'"
            :src="require('@/assets/images/global/ar-lang.svg')"
          />
          <img
            width="18px"
            v-else-if="language == 'en'"
            :src="require('@/assets/images/global/en-lang.svg')"
          />
          <img
            width="20px"
            v-else
            :src="require(`@/assets/images/global/tr-lang.png`)"
          />
        </div>
      </template>

      <div :elevation="0">
        <div
          v-for="(lang, i) in languages"
          :key="i"
          @click="$emit('switchLanguage', lang)"
          class="text-center d-flex align-center px-2 py-1 white"
        >
          <img class="mx-1" width="18px" :src="lang.img" />
          <v-list-item-title
            class="cursor-pointer font-14"
            :class="$t(`global.${lang.title}`)"
            >{{ $t(`global.${lang.title}`) }}</v-list-item-title
          >
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    lang: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["translate"]),
    language() {
      return localStorage.getItem("language") || "en";
    },
  },
  data: () => ({
    languages: [
      {
        title: "english",
        value: "en",
        img: require("@/assets/images/global/en-lang.svg"),
      },
      {
        title: "arabic",
        value: "ar",
        img: require("@/assets/images/global/ar-lang.svg"),
      },
      {
        title: "turkish",
        value: "tr",
        img: require("@/assets/images/global/tr-lang.png"),
      },
    ],
  }),
};
</script>

<style lang="scss">
.language-title {
  line-height: normal !important;
}
.v-list-item .v-list-item__title {
  line-height: unset !important;
}
</style>
