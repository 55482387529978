export default {
  global: {
    english: "English",
    arabic: "العربية",
    turkish: "Türkçe",
    noItems: "Hiç bir ürün bulunamadı...",
    changeLocation: "Şube değiştir",
    noCategory: "Bu Kategori Şuan Mevcut Değil",
    noProduct: "Bu Ürün Şuan Mevcut Değil",
    searchProduct: "Ara",
    cartEmpty: "Sepetiniz boş...",
    cart: "Sepet",
    total: "Toplam",
    open: "Aç",
    request: "Sipariş Ver",
    completeRequest: "Siparişi Tamamla",
    widthCoupon: "Kuponlu",
    addToCart: "Sepete ekle",
    sendOrder: "Sipariş Ver",
    favoriteEmpty: "Favori Listeniz Boş...",
    seeProducts: "Ürünleri Gör",
    add: "Ekle",
    backToProducts: "Ürünlere Geri Dön",
    yourFavoriteList: "Favori Listeniz",
    writeYourNotes: "Notlarınızı yazınız...",
    price: "Fiyat",
    requestDetails: `Siparişiniz  Detayları`,
    name: "Adı",
    delivery: "Teslimat",
    note: "Not...",
    sendByWhatsapp: "Whatsapp ile Gönder",
    yourAddress: "adresiniz veya adres bağlantınızı kopyalayıp yapıştırın",
    pickUp: "Gel-Al",
    none: "Boş",
    use: "kullan",
    useCoupon: "koponu kullan",
    enterPhone: "Numaranızı yazınız",
    yes: "Evet",
    no: "Hayır",
    totalPrice: "Toplam",
    couponAmount: "Kupon Tutarı",
    generalTotal: "Genel Toplam",
    productsAmount: "Ürün Miktarı",
    orderNotes: "Sipariş Notları",
    username: "Kullanıcı adı",
    userPhone: "Kullanıcı Telefonu",
    toAddress: "Paket ",
    userAddress: "Kullanıcı Adresi",
    stillNeed : 'Daha ',
    toUseCoupon: 'lazım',

    mustNotZero: 'bos olmaz',
    itemAddedToCart: 'karta eklendi',
    selectLocation: 'istediğin Lokantayı seç',
    phoneNumber: 'Telefon Numarası',
    nameRequire: 'Ad alanı zorunludur',
    googleForm: 'Aramıza katılmak için:',
    orderLimit: 'minimum toplam fiyat ',
    siteIsOff: 'web sitesi yapım aşamasındadır'


  },
};
